import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import settings from "../../../settings";
import useWindowSize from "../../hooks/useWindowSize";

const arrow = `${settings.IMAGES_BASE_URL}/v2/images/scroll-arrow-image.svg`;
const TopBanner = (props) => {
	const scrollToDesktopRef = () => {
		window.scrollTo({
			top: 700,
			left: 0,
			behavior: "smooth",
		});
	};

	const { width } = useWindowSize();
	const isMobile = width < 1200;
	const isDesktop = width >= 1200;
	return (
		<>
			{isMobile && (
				<section className="top__banner__mobile">
					<LazyLoadImage
						effect="blur"
						className={props.mobileImageClassName}
						src={props.mobileImage}
						alt={props.header}
					/>
					<div className="container">
						<div className="top__banner__text">
							{props.header && <h1>{props.header}</h1>}
							<h2>
								{props.subheader && <span>{props.subheader}</span>}
								{props.subheader && <br />}
								{props.pricing && <span>{props.pricing}</span>}
							</h2>
							{props.text && <p>{props.text}</p>}
							<>{props.children}</>
						</div>
						<div className="buttons">
							{props.buttonText && (
								<a
									href={props.butttonURL}
									starget="_blank"
									rel="noopener noreferrer"
									onClick={props.onClickFirstButton}
								>
									<button className="btn btn-blue">{props.buttonText}</button>
								</a>
							)}
              {props.buttonTwoText && props.showbuttonTwoTextInMob && (
                <a
                  href={props.butttonTwoURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={props.scrollToView ? props.scrollToView : undefined}
                >
                  <button className="btn btn-blue">
                    {props.buttonTwoText}
                  </button>
                </a>
              )}
							{props.agendaPopup && <div>{props.agendaPopup}</div>}
						</div>
						{props.note && <p className="note">{props.note}</p>}
					</div>
				</section>
			)}
			{isDesktop && (
				<section
					className={`top__banner ${props.arrow ? "border_bottom mb_5" : ""}`}
					style={{ backgroundImage: `url(${props.image})` }}
				>
					<div className="container">
						<div className="top__banner__content">
							<div className="top__banner__text">
								{props.header && <h1>{props.header}</h1>}
								<h2>
									{props.subheader && <span>{props.subheader}</span>}
									{props.subheader && <br />}
									{props.pricing && <span>{props.pricing}</span>}
								</h2>
								{props.text && <p>{props.text}</p>}
								<>{props.children}</>
							</div>
							<div className={`buttons ${props.buttonTwoText ? "column" : ""}`}>
								<div className="external__buttons">
									{props.buttonText && (
										<a
											href={props.butttonURL}
											target="_blank"
											rel="noopener noreferrer"
											onClick={props.onClickFirstButton}
										>
											<button className="btn btn-blue">
												{props.buttonText}
											</button>
										</a>
									)}
									{props.buttonTwoText && (
										<a
											href={props.butttonTwoURL}
											target="_blank"
											rel="noopener noreferrer"
                      onClick={props.scrollToView ? props.scrollToView : undefined}
										>
											<button className="btn btn-blue">
												{props.buttonTwoText}
											</button>
										</a>
									)}
								</div>
								{props.agendaPopup && (
									<div className={`${props.buttonTwoText ? "mt_4" : ""}`}>
										{props.agendaPopup}
									</div>
								)}
							</div>
							{props.note && <p className="note">{props.note}</p>}
							{props.arrow && (
								<button
									className="arrow"
									onClick={scrollToDesktopRef}
									onKeyDown={scrollToDesktopRef}
								>
									<LazyLoadImage
										effect="blur"
										src={arrow}
										alt="arrow"
										className="scroll-arrow"
									/>
								</button>
							)}
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default TopBanner;
